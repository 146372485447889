.ptsImportModal.ptsImportModal {
  --modal-width: 480px;
}

.body {
  overflow-y: auto;
}

.footer {
}

.preview {
}

.previewItem.previewItem {
  align-items: initial;
  font-family: "NKDuy Mono", monospace;
}

.previewItemTitle {
  display: flex;
  align-items: center;
  flex-flow: row nowrap;
  gap: 1rem;
  width: 100%;
  font-family: "NKDuy Mono", monospace;
}

.previewItemCount {
  margin-right: auto;
}

.pillUpdate.pillUpdate {
  display: flex;
  gap: 0.25rem;
  cursor: pointer;

  &:not(:hover) {
    background: #64717c;
    color: #f0f0f0;
  }

  &:hover {
    .pillUpdateIcon {
      opacity: 1;
    }
  }
}

.pillUpdateIcon {
  width: 1.2em;
  height: 1.2em;
  flex: 0 0 1.2em;
  opacity: 0.5;
}

.previewItemSubtitle {
  display: flex;
  flex-flow: column nowrap;
  gap: 0.5rem;
}

.previewList {
  display: flex;
  flex-flow: column nowrap;
  gap: 0.2rem;
}

.previewListValue {
  font-family: "NKDuy Mono", monospace;
}

.help.help {
  & > * {
    padding-bottom: 0;
  }
}

.helpContent {
  font-family: "NKDuy Mono", monospace;
  color: #4a545c;

  strong {
    color: #000;
  }
}

.errors {
  display: flex;
  flex-flow: column nowrap;
  gap: 0.5rem;
  margin-top: 1rem;
  margin-bottom: 2rem;

  .error {
    font-family: "NKDuy Mono", monospace;
    color: red;
    font-weight: bold;
    text-transform: none;
    letter-spacing: normal;
    padding-top: 1rem;
    user-select: text;
  }
}
